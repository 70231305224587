import React from "react"
import { StaticQuery, graphql } from "gatsby"
// import {FaEnvelope, FaLocationArrow, FaFacebook, FaLinkedin} from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import SiteImg1 from "../assets/img/component-site.png"



const TermsConditionsPage = () => (
  <StaticQuery
    query={graphql`
      query{
        allStrapiPages{
          edges {
            node {
              PageTitle
            }
          }
        }
      }
    `}

    render={data => (
      
    <Layout pageData={""}>
        <SEO title="Terms and Conditions" />


        <section className="contact-form-section">

          <h1>Terms and Conditions</h1>

          <div className="full-width-paragraph">
            				
            <div class="box-underline-text"><div class="subtitle-chapters-underline-effect">The Terms and Conditions applies to everyone who uses this website or contact SEO Developments.</div></div>
            <p>One of our legally trained advisors will listen sympathetically to you and take the details of your accident. Where the advisor feels you would benefit from legal advice and your claim merits further investigation, we will offer to put you in touch with a specialist solicitor without delay. SEO Developments will not charge you anything for doing this. We will not ask you to take out any loan, sign anything, or enter into any contract. We do not give you legal advice. That is the job of specialist solicitors.</p>
            <p>If we feel we cannot help you, we will let you know, and of course you are free to seek advice elsewhere immediately.</p>
            <p>We will assist you in making contact with an independent firm of solicitors. You are free to choose another solicitor. Any solicitor we refer you to is an independent professional from whom you should receive impartial and confidential advice. The solicitors should always act in your best interest, this is a requirement of their professional body.</p>
            <p><a href="https://www.seodevelopments.co.uk/" class="links">SEO Developments</a> is not in any way responsible for the advice given or other work undertaken on your behalf by these solicitors firms. Your solicitor firm will give you their independent advice about the use of legal expenses insurance and any medico-legal reporting agency.</p>
            <p>This is important. Although you generally have 3 years (or longer for those under 18 or suffering from specific illnesses) from the accident date until your claim is time-barred, it is best to gather the evidence while memories are fresh, and get the legal and rehabilitation help when you need it most.</p>
            <p>When telephoning <a href="https://www.seodevelopments.co.uk/" class="links">SEO Developments</a> we inform you that calls may be recorded and used for internal training and regulatory compliance purposes only.</p>
            <p>If you phone us between 10:00 – 19:00 Monday to Friday,&nbsp; you will be put through directly to a AFM specialist advisor who will be able to talk you through the claims process and any queries you may have. If you phone at any other time, you will be put through to our call center where we will take your details and arrange for a specialist advisor to call you back during the times above.</p>
            <p>However out of office hours you can always email us at <a href="mailto:info@seodevelopments.co.uk" class="links">info@seodevelopments.co.uk</a></p>
            <p>We operate a zero tolerance policy towards the abuse of our employees who have a right to carry out their work without fear of being abused. Malicious or abusive calls are a criminal offense under Section 43 of the Telecommunications Act 1984, and <a href="https://www.seodevelopments.co.uk/" class="links">SEO Developments</a> will not hesitate to report such acts to the police.</p>

          </div>
          
        </section>


      </Layout>

    )}
  
  />
)

export default TermsConditionsPage
